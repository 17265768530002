import axios from 'axios';

const login = (data) => (
  axios.post(
    `${process.env.REACT_APP_USER_API_ENDPOINT}/auth/login`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ));

export default login;
