import axios from 'axios';

import { getAccessToken } from '../../../../helpers/localStorage';

const updateUserById = (userId, data) => (
  axios.put(
    `${process.env.REACT_APP_USER_API_ENDPOINT}/companies/users/${userId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    },
  ));

export default updateUserById;
