import { css } from '@emotion/react';

import { colors, contentWidth } from '../../../configs';

export const navbarStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: ${colors.PRIMARY_BLUE};
`;

export const navbarContentStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${contentWidth.FULL}px;
  height: 100%;
`;

export const rightMenuStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 100%;
`;

export const listContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const listStyles = css`
  height: 100%;
`;

export const linkStyles = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  text-transform: capitalize;
  font-weight: 600;
  color: ${colors.WHITE};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: ${colors.TRANSPARENT};
  }

  &.isActive {
    &:after {
      background-color: ${colors.PRIMARY_YELLOWISH_ORANGE};
    }
  }
`;

export const notificationIconStyles = css`
  font-size: 16px;
  color: ${colors.WHITE};
`;

export const dividerStyles = css`
  width: 1px;
  height: calc(100% - 50px);
  margin: 0 40px;
  background-color: rgba(255, 255, 255, 0.15);
`;

export const localeDropdownContainerStyles = css`
  position: relative;
`;

export const localeDropdownButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  padding: 0;
  border: none;
  background-color: ${colors.TRANSPARENT};
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const flagStyles = css`
  width: 22px;
  height: 22px;
  margin: 0 8px 0 0;
`;

export const languageStyles = css`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.WHITE};
`;

export const arrowDownStyles = css`
  margin: 0 0 0 5px;
  font-size: 20px;
  color: ${colors.WHITE};
`;

export const localeDropdownStyles = (isDropdownShown) => css`
  display: ${isDropdownShown ? 'block' : 'none'};
  position: absolute;
  width: 145px;
  margin: 5px 0 0 0;
  padding: 10px 5px;
  right: 0;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: ${colors.WHITE};
  z-index: 50;
`;

export const localeDropdownListStyles = css``;

export const localeDropdownLanguageButtonStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 35px;
  border: none;
  border-radius: 5px;
  background-color: ${colors.TRANSPARENT};
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.PRIMARY_BLACK};
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: ${colors.LIGHT_BLUE};
    color: ${colors.WHITE};
  }
`;

export const profileDropdownContainerStyles = css`
  position: relative;
`;

export const profileDropdownButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0;
  border: none;
  background-color: ${colors.TRANSPARENT};
  font-weight: 600;
  font-size: 14px;
  color: ${colors.WHITE};
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const profileDropdownStyles = (isDropdownShown) => css`
  display: ${isDropdownShown ? 'block' : 'none'};
  position: absolute;
  width: 190px;
  margin: 5px 0 0 0;
  padding: 5px;
  right: 0;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: ${colors.WHITE};
  z-index: 50;
`;

export const dropdownLinkStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 12px;
  border-radius: 5px;
  background-color: ${colors.TRANSPARENT};
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.PRIMARY_BLACK};
  outline: none;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: ${colors.LIGHT_BLUE};
    color: ${colors.WHITE};
    opacity: unset;
  }
`;

export const dropdownDivider = css`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: rgba(29, 61, 113, 0.15);
`;

export const logoutButtonStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 12px;
  border: none;
  border-radius: 5px;
  background-color: ${colors.TRANSPARENT};
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.DANGER_RED};
  outline: none;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: ${colors.LIGHT_BLUE};
    color: ${colors.WHITE};
    opacity: unset;
  }
`;
