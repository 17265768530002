import axios from 'axios';

import { getAccessToken } from '../../../../helpers/localStorage';

const deleteUserById = (userId) => (
  axios.delete(
    `${process.env.REACT_APP_USER_API_ENDPOINT}/companies/users/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default deleteUserById;
