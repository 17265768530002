import axios from 'axios';

import { getAccessToken } from '../../../../helpers/localStorage';

const updateUser = (data) => (
  axios.put(
    `${process.env.REACT_APP_USER_API_ENDPOINT}/users`,
    data,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    },
  ));

export default updateUser;
