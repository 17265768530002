import axios from 'axios';

const introspection = (accessToken) => (
  axios.post(
    `${process.env.REACT_APP_USER_API_ENDPOINT}/auth/introspection`,
    { access_token: accessToken },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  ));

export default introspection;
