import {
  ApplicantsPage,
  ApplicantDetailsPage,
  ChangeEmailPage,
  ChangePasswordPage,
  CompanyProfilePage,
  CompanyProfileSettingsPage,
  CreateJobPage,
  CreateJobPreviewPage,
  DuplicateJobPage,
  DuplicateJobPreviewPage,
  EditJobPage,
  EditJobPreviewPage,
  Error404Page,
  Error500Page,
  FavoriteCandidatesPage,
  JobDetailsPage,
  JobDetailsPreviewPage,
  JobPage,
  LoginPage,
  ManageMembersSettingsPage,
  PrivacyAndPolicyPage,
  ResumePage,
  TermsAndConditionPage,
  UserProfileSettingsPage,
} from '../pages';

export default [
  {
    path: '/:locale/job/:jobId/applicants',
    component: () => <ApplicantsPage />,
    isProtected: true,
  },
  {
    path: '/:locale/job/:jobId/applicant/:applicantId',
    component: () => <ApplicantDetailsPage />,
    isProtected: true,
  },
  {
    path: '/:locale/profile-and-settings/change-email',
    component: () => <ChangeEmailPage />,
    isProtected: true,
  },
  {
    path: '/:locale/profile-and-settings/change-password',
    component: () => <ChangePasswordPage />,
    isProtected: true,
  },
  {
    path: '/:locale/company-profile',
    component: () => <CompanyProfilePage />,
    isProtected: true,
  },
  {
    path: '/:locale/profile-and-settings/company-profile',
    component: () => <CompanyProfileSettingsPage />,
    isProtected: true,
  },
  {
    path: '/:locale/job/create',
    component: () => <CreateJobPage />,
    isProtected: true,
  },
  {
    path: '/:locale/job/create/preview',
    component: () => <CreateJobPreviewPage />,
    isProtected: true,
  },
  {
    path: '/:locale/job/:jobId/duplicate',
    component: () => <DuplicateJobPage />,
    isProtected: true,
  },
  {
    path: '/:locale/job/:jobId/duplicate/preview',
    component: () => <DuplicateJobPreviewPage />,
    isProtected: true,
  },
  {
    path: '/:locale/error-500',
    component: () => <Error500Page />,
    isProtected: false,
  },
  {
    path: '/:locale/job/:jobId/edit',
    component: () => <EditJobPage />,
    isProtected: true,
  },
  {
    path: '/:locale/job/:jobId/edit/preview',
    component: () => <EditJobPreviewPage />,
    isProtected: true,
  },
  {
    path: '/:locale/favorite-candidates',
    component: () => <FavoriteCandidatesPage />,
    isProtected: true,
  },
  {
    path: '/:locale/job/:jobId',
    component: () => <JobDetailsPage />,
    isProtected: true,
  },
  {
    path: '/:locale/job/:jobId/preview',
    component: () => <JobDetailsPreviewPage />,
    isProtected: true,
  },
  {
    path: '/:locale/job',
    component: () => <JobPage />,
    isProtected: true,
  },
  {
    path: '/:locale/login',
    component: () => <LoginPage />,
    isProtected: false,
  },
  {
    path: '/:locale/privacy-policy',
    component: () => <PrivacyAndPolicyPage />,
    isProtected: true,
  },
  {
    path: '/:locale/profile-and-settings/manage-members',
    component: () => <ManageMembersSettingsPage />,
    isProtected: true,
  },
  {
    path: '/:locale/profile-and-settings/user-profile',
    component: () => <UserProfileSettingsPage />,
    isProtected: true,
  },
  {
    path: '/:locale/resume',
    component: () => <ResumePage />,
    isProtected: true,
  },
  {
    path: '/:locale/terms-and-condition',
    component: () => <TermsAndConditionPage />,
    isProtected: true,
  },
  {
    path: '*',
    component: () => <Error404Page />,
    isProtected: false,
  },
];
